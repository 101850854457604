import React from "react";

type CenterErrorContentProps = {
  message: string;
}

export const CenterErrorContent: React.FC<CenterErrorContentProps> = ({ message }) => {
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "lighter",
        fontSize: "55px"
      }}
    >
      {message}
    </div>
  )
}