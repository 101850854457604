import React, { PropsWithChildren } from "react";

type CenterContentProps = {
  gap: string;
  marginTop: string;
}

export const CenterContent: React.FC<PropsWithChildren<CenterContentProps>>  = ({ children, gap, marginTop }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        gap,
        justifyContent: "center",
        flexDirection: "column",
        marginTop
      }}
    >
      {children}
    </div>
  )
}