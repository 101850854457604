import React from "react";

import { CenterContent } from "components/center-content/center-content";
import GetAroundLogo from "../../logo/getaround.svg";
import { MainHeader } from "pages/main-component/components/main-header/main-header";
import { MainNavigationDrawer } from "pages/main-component/components/main-navigation-drawer.tsx/main-navigation-drawer";
import { CenterErrorContent } from "components/center-error-content/center-error-content";

export const ErrorPage: React.FC = () => {

  return (
    <>
      <MainHeader />
      <MainNavigationDrawer />
      <CenterContent
        gap="170px"
        marginTop="20vh"
      >
        <div>
          <img
            src={GetAroundLogo}
            alt=""
            style={{
              height: "150px"
            }}
          />
          <CenterErrorContent
            message="An Error Occurred!"
          />
        </div>
      </CenterContent>
    </>
  );
};