import { useState, useEffect } from "react";
import * as Utils from "./generated-quote.utils";

/**
 * @description Generates a random quote whenever any state passed in the "trigger" prop gets updated.
 */
const GeneratedQuote: React.FC = () => {
  const [quote, setQuote] = useState<Utils.Quote>({ quote: "", author: "" });
  const [lock, setLock] = useState<boolean>(false);

  useEffect(() => {
    if (lock) return;
    setLock(true);
    const quote = Utils.getRandomQuote();
    setQuote(quote);
    setTimeout(() => {
      setLock(false);
    }, 1000);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [setQuote, Utils.getRandomQuote, setLock]);

  return (
    <p>
      "{quote.quote}" - {quote.author}
    </p>
  );
};

export default GeneratedQuote;
