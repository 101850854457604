import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet, useNavigate, matchPath, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";

import { Login } from "pages/login/login.page"
import { LoadingCenterNavigator } from "components/loading-center-navigator/loading-center-navigator";
import { MainHeader } from "pages/main-component/components/main-header/main-header";
import { MainNavigationDrawer } from "pages/main-component/components/main-navigation-drawer.tsx/main-navigation-drawer";
import { Seo } from "components/seo/seo";

export const MainComponent: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isRouteBase = matchPath("/", pathname)

  const isAdmin = auth.user?.profile?.roles === "admin";
  const { isAuthenticated, activeNavigator } = auth;

  useEffect(() => {
    if (isAuthenticated && isRouteBase && isAdmin) {
      navigate("/event-search/entity-history");
    }
  }, [isAuthenticated, isRouteBase, isAdmin, navigate])

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      console.log("Refreshing token.");
    })
  }, [auth.events]);

  if (activeNavigator === "signinPopup")
    return (
      <LoadingCenterNavigator
        text="Signin in Popup..."
      />
    )

  if (activeNavigator === "signinSilent")
    return (
      <LoadingCenterNavigator
        text="Signing you in..."
      />
    )

  if (auth.isLoading)
    return (
      <LoadingCenterNavigator
        text="Loading"
      />
    )

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (isAuthenticated && !isAdmin) {
    auth.removeUser();
  }

  if (isAuthenticated && isAdmin) {
    return (
      <Seo title="Event Explorer" description="Explore events to your heart's content through EvE.">
        <MainHeader />
        <MainNavigationDrawer />
        <Container
          maxWidth="xl"
          style={{
            paddingTop: 50,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 10,
            marginTop: 20,
          }}
        >
          <Outlet />
        </Container>
      </Seo>
    );
  }

  return <Login />
}
