import React from "react";
import { Helmet } from "react-helmet";

type SeoProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
};

export const Seo: React.FC<SeoProps> = (props) => {
  const titlePrefix = process.env.REACT_APP_ENV === "staging" ? "Staging | " : "";

  return (
    <>
      <Helmet>
        <title>{titlePrefix + props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      {props.children}
    </>
  );
};
