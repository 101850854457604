import React, { useEffect, useState, useContext } from "react";
import { useOutletContext, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Seo } from "components/seo/seo";
import * as Utils from "pages/event-search/event-search.utils";
import ExpandibleTable from "components/expandible-table/expandible-table";
import {
  FormValues,
  InitialFormValues,
} from "pages/event-search/components/event-search-form/event-search-form-utils";
import { HttpClientContext } from "globals/context/http-client-context";
import LoadingSpinner from "components/loading-spinner";
import { CenterErrorContent } from "components/center-error-content/center-error-content";

type InstigatorRequestType = {
  field: "instigatorId";
  label: string;
  method: "getByInstigatorId";
};

type UserActivityByIdProps = {
  request: InstigatorRequestType;
  submit: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  formValues: FormValues;
  setIsLoadingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingEvents: boolean;
};

export const UserActivityById: React.FC = () => {
  const {
    request,
    submit,
    setSubmit,
    setFormValues,
    formValues,
    setIsLoadingEvents,
    isLoadingEvents,
  } = useOutletContext<UserActivityByIdProps>();
  const [searchParams] = useSearchParams();
  const { id: instigatorId } = useParams();
  const [fetchMore, setFetchMore] = useState<string | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const { eventsHttpClient: EventsServiceClient } = useContext(HttpClientContext);
  const [requestState, setRequestState] = useState<{
    events: any[];
    exceptionMessage?: string;
  }>({
    events: [],
  });

  const handleFetchMore = () => {
    setIsFetchingMore(true);
    setFetchMore(formValues.nextPage);
  };

  useEffect(() => {
    if (submit) {
      setRequestState({
        events: [],
        exceptionMessage: undefined,
      });
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      nextPage: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instigatorId]);

  useEffect(() => {
    if (searchParams.toString() === "") return;

    if (submit) {
      setFormValues({
        ...formValues,
        nextPage: null,
      });
      return;
    }

    const initialParams: Record<string, any> = {
      ...InitialFormValues,
    };

    const rawObj: Record<string, any> = Object.fromEntries(
      new URLSearchParams(searchParams.toString()),
    );

    if (rawObj["startTime"]) rawObj["startTime"] = dayjs(rawObj["startTime"]);
    if (rawObj["endTime"]) rawObj["endTime"] = dayjs(rawObj["endTime"]);

    const total: Partial<FormValues> = {
      ...initialParams,
      ...rawObj,
      instigatorId,
    };

    const compTotal = {
      ...total,
      nextPage: "",
    };

    const compForm = {
      ...formValues,
      nextPage: "",
    };

    const pageCursor =
      JSON.stringify(compTotal) === JSON.stringify(compForm) ? formValues.nextPage : undefined;

    total.nextPage = pageCursor;

    setFormValues(total as FormValues);

    (async () => {
      try {
        const startTimeTs = total.startTime
          ? new Date(total.startTime).getTime().toString()
          : undefined;
        const endTimeTs = total.endTime ? new Date(total.endTime).getTime().toString() : undefined;

        if (!fetch) {
          return;
        }

        !isFetchingMore && setIsLoadingEvents(true);

        const res = await EventsServiceClient!.events[request.method]({
          id: instigatorId!,
          limit: 50,
          pageCursor: pageCursor || undefined,
          startTime: startTimeTs,
          endTime: endTimeTs,
          order: total.order,
        });

        if (!pageCursor) {
          setRequestState({
            events: res.events,
            exceptionMessage: undefined,
          });
          setFormValues((prev) => ({
            ...prev,
            nextPage: res.nextPageCursor || null,
          }));
          setIsLoadingEvents(false);
          setIsFetchingMore(false);
          return;
        }

        setRequestState((prev) => ({
          events: [...prev.events, ...res.events],
          exceptionMessage: undefined,
        }));
        setFormValues((prev) => ({
          ...prev,
          nextPage: res.nextPageCursor || null,
        }));
        setIsLoadingEvents(false);
        setIsFetchingMore(false);
      } catch (err: any) {
        console.warn(err);
        setRequestState({
          events: [],
          exceptionMessage: err.body?.message || err.message,
        });
        setIsLoadingEvents(false);
        setIsFetchingMore(false);
      }
    })();

    return () => {
      setFormValues(InitialFormValues);
    };

    // if we add formikContext it will continously re render
    // same goes for requestState.nextPageCursor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, instigatorId, request.method, fetchMore, EventsServiceClient, submit]);

  const parsedEvents = requestState.events && Utils.parseEventInfo(requestState.events);

  return isLoadingEvents ? (
    <LoadingSpinner />
  ) : (
    <Seo
      title="Event Explorer - Instigator Search by Id"
      description="Search for everything that a user has done in our system by user id."
    >
      {requestState.exceptionMessage && (
        <CenterErrorContent message={requestState.exceptionMessage} />
      )}
      {parsedEvents.length !== 0 && (
        <ExpandibleTable
          items={parsedEvents}
          rowConfiguration={[
            { label: "Event", accessor: "eventName" },
            { label: "Instigator", accessor: "instigator.email" },
            { label: "Role", accessor: "instigator.role" },
            { label: "Date", accessor: "date" },
            { label: "Origin", accessor: "trace.genesis" },
          ]}
          collapsibleInternalLinks={[
            {
              relativePath: "../../entity-history",
              urlString: "#id?order=desc",
              labelString: "#domain History",
              dynamicAttributes: {
                "#id": "eventData.id",
                "#domain": "metadata.domain",
              },
            },
          ]}
          collapsibleExternalLinks={[
            {
              labelString: "Datadog Logs",
              urlString: `https://app.datadoghq.com/logs?query=@traceIds.traceparent:"#traceparent"&from_ts=#from_date&to_ts=#to_date&live=true`,
              dynamicAttributes: {
                "#traceparent": "trace.traceparent",
                "#from_date": String(Date.now() - 7 * 24 * 60 * 60 * 1000),
                "#to_date": String(Date.now()),
              },
            },
          ]}
          collapsibleJson={[
            { title: "Data", accessor: "eventData" },
            { title: "Instigator", accessor: "instigator" },
            { title: "Traces", accessor: "trace" },
            { title: "Metadata", accessor: "metadata" },
          ]}
        />
      )}
      {formValues.nextPage && (
        <Box>
          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 10, marginBottom: 30 }}
            onClick={handleFetchMore}
            disabled={isFetchingMore}
          >
            FETCH MORE
          </Button>
        </Box>
      )}
    </Seo>
  );
};
