/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * **[Internal]** Route that returns an Array of Events based on the entity id provided.
     *
     * **Query Params**
     *
     * Send the `limit` query param to limit the number of events to retrieve.
     * Send the `nextPageCursor` query param to paginate results.
     * Send the `startTime` query param as a timestapm in ms string to set a start time for the events you want to search for.
     * Send the `endTime` query param as a timestapm in ms string to set an end time for the events you want to search for.
     *
     * You have to provide both "startTime" and "endTime" to get events within a timeframe.
     *
     * @returns any Events by entity id (an entity could be a car id, user id, rental id, etc)
     * @throws ApiError
     */
    public getByEntityId({
        id,
        limit,
        pageCursor,
        startTime,
        endTime,
        order,
    }: {
        id: string,
        limit?: number,
        pageCursor?: string,
        startTime?: string,
        endTime?: string,
        order?: 'asc' | 'desc',
    }): CancelablePromise<{
        events: Array<{
            entityId: string;
            'timestamp#eventName#instigatorId#domain': string;
            timestamp: number;
            eventName: string;
            instigatorId: string;
            domain: string;
            data: Record<string, any>;
        }>;
        count: number;
        nextPageCursor: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/entity/{id}',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'pageCursor': pageCursor,
                'startTime': startTime,
                'endTime': endTime,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Unhandled Error`,
            },
        });
    }

    /**
     * **[Internal]** Route that returns an Array of Events based on the instigator id provided.
     *
     * **Query Params**
     *
     * Send the `limit` query param to limit the number of events to retrieve.
     * Send the `nextPageCursor` query param to paginate results.
     * Send the `startTime` query param as a timestapm in ms string to set a start time for the events you want to search for.
     * Send the `endTime` query param as a timestapm in ms string to set an end time for the events you want to search for.
     *
     * You have to provide both "startTime" and "endTime" to get events within a timeframe.
     *
     * @returns any Events by instigator id (user id of the user that produces the event)
     * @throws ApiError
     */
    public getByInstigatorId({
        id,
        limit,
        pageCursor,
        startTime,
        endTime,
        order,
    }: {
        id: string,
        limit?: number,
        pageCursor?: string,
        startTime?: string,
        endTime?: string,
        order?: 'asc' | 'desc',
    }): CancelablePromise<{
        events: Array<{
            entityId: string;
            'timestamp#eventName#instigatorId#domain': string;
            timestamp: number;
            eventName: string;
            instigatorId: string;
            domain: string;
            data: Record<string, any>;
        }>;
        count: number;
        nextPageCursor: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/instigator/{id}',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'pageCursor': pageCursor,
                'startTime': startTime,
                'endTime': endTime,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Unhandled Error`,
            },
        });
    }

}
