import React, { createContext, PropsWithChildren } from "react";
import { useAuth } from "react-oidc-context";

import { EventsClient } from "../clients/events-service/http-client";

type HttpClientContextProps = {
  eventsHttpClient: EventsClient | undefined
};

export const HttpClientContext = createContext<HttpClientContextProps>({eventsHttpClient: undefined});

export const HttpClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const idToken = auth.user?.id_token;

  const EventsServiceClient = new EventsClient({
    BASE: process.env.REACT_APP_EVENTS_SERVICE_BASE_URL!,
    HEADERS: {
      "x-client-name": "event-explorer",
      "x-client-version": "0.1",
      Authorization: `Bearer ${idToken}`,
    },
  });

  return (
    <HttpClientContext.Provider
      value={{
        eventsHttpClient: EventsServiceClient
      }}
    >
      {children}
    </HttpClientContext.Provider>
  )
}

