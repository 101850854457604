import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { createBrowserRouter, RouterProvider, RouteObject } from "react-router-dom";
import { theme } from "theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { oidcConfig } from "./config";

import { HttpClientProvider } from "globals/context/http-client-context";
import { UIInteractionProvider } from "globals/context/ui-interaction-context";

import { MainComponent } from "pages/main-component/main-component.page";
import { PrivateRoute } from "components/private-route/private-route";
import { EventSearch } from "pages/event-search/event-search.page";
import { ErrorPage } from "pages/error-page/error-page.page";
import { EntityHistory } from "pages/event-search/entity-history/entity-history.page";
import { EntityHistoryById } from "pages/event-search/entity-history/entity-history-by-id/entity-history-by-id.page";
import { UserActivity } from "pages/event-search/user-activity/user-activity.page";
import { UserActivityById } from "pages/event-search/user-activity/user-activity-by-id/user-activity-by-id.page";

const config = {
  ...oidcConfig,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const routes: RouteObject[] = [
  {
    path: "/",
    /**
     * App loads all the logic to auth
     * Should have pagewide headers, sidebars and outlet
     */
    element: <MainComponent />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "event-search",
        element: (
          <PrivateRoute>
            <EventSearch />
          </PrivateRoute>
        ),
        children: [
          {
            path: "entity-history",
            element: (
              <PrivateRoute>
                <EntityHistory />
              </PrivateRoute>
            ),
            children: [
              {
                path: ":id",
                element: (
                  <PrivateRoute>
                    <EntityHistoryById />
                  </PrivateRoute>
                )
              },
            ]
          },
          {
            path: "user-activity",
            element: (
              <PrivateRoute>
                <UserActivity />
              </PrivateRoute>
            ),
            children: [
              {
                path: ":id",
                element: (
                  <PrivateRoute>
                    <UserActivityById />
                  </PrivateRoute>
                )
              },
            ]
          },
        ]
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export const App: React.FC = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AuthProvider {...config}>
      <UIInteractionProvider>
        <HttpClientProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </HttpClientProvider>
      </UIInteractionProvider>
    </AuthProvider>
  </LocalizationProvider>
);
