import { useContext } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import GeneratedQuote from "pages/main-component/components/main-header/generated-quote";
import { UIInteractionContext } from "globals/context/ui-interaction-context";

export const MainHeader: React.FC = () => {
  const { setIsNavigationDrawerOpen } = useContext(UIInteractionContext);

  return (
    <AppBar>
      <Toolbar>
        <IconButton 
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setIsNavigationDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
          EVENT EXPLORER
        </Typography>
        <Typography variant="subtitle2" component="div">
          <GeneratedQuote />
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
