import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#800080',
    },
    secondary: {
      main: '#8000801F',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: () => ({
          textDecoration: "none"
        })
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ".MuiListItemIcon-root": {
            color: theme.palette.primary.main
          },
          ":hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,

            ".MuiListItemIcon-root": {
              color: theme.palette.primary.main
            }
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,

            ".MuiListItemIcon-root": {
              color: theme.palette.primary.main
            }
          },
        })
      }
    }
  }
});