import React, { useState } from "react";
import { Outlet } from "react-router";

import EventSearchForm from "pages/event-search/components/event-search-form/event-search-form";
import { Seo } from "components/seo/seo";
import {
  FormValues,
  InitialFormValues,
} from "pages/event-search/components/event-search-form/event-search-form-utils";

export const EntityHistory: React.FC = () => {
  const [submit, setSubmit] = useState<boolean>(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(InitialFormValues);

  return (
    <Seo
      title="Event Explorer - Entity Search"
      description="Search for everything that happened to something in our system using its id."
    >
      <EventSearchForm
        formValues={formValues}
        request={{
          field: "entityId",
          label: "Entity Id: user id, rental id, claim id, car id, driver id, owner id",
          method: "getByEntityId",
        }}
        setSubmit={setSubmit}
        setIsLoadingEvents={setIsLoadingEvents}
        isLoadingEvents={isLoadingEvents}
        formDescription="Search the history for an entity in our system through the use of any id."
      />
      <Outlet
        context={{
          request: {
            field: "entityId",
            label: "Entity Id",
            method: "getByEntityId",
          },
          submit,
          setSubmit,
          setFormValues,
          formValues,
          setIsLoadingEvents,
          isLoadingEvents,
        }}
      />
    </Seo>
  );
};
