import CircularProgress from "@mui/material/CircularProgress";

/**
 * @description renders an animated spinner centered on the parent component.
 *
 * @property "style" Overwrites default style.
 */
const LoadingSpinner: React.FC<{ style?: React.CSSProperties  }> = (props) => (
  <div
    style={ props.style || {
      display: "flex",
      justifyContent: "center",
      paddingTop: 40,
    }}
  >
    <CircularProgress />
  </div>
);

export default LoadingSpinner;
