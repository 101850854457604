import React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import * as Utils from "./event-search-form-utils";
import { FormValues } from "pages/event-search/components/event-search-form/event-search-form-utils";

/**
 * @description Handles all form and fetch related logic for the event-search page and renders children passed within it.
 *
 * @property (optional) "children" Components that will be rendered within the EventSearchForm.
 * @property (optional) "debug" Renders the form state in <pre> tags for debug when passed as true.
 */
const EventSearchForm: React.FC<{
  children?: React.ReactNode;
  debug?: boolean;
  request: {
    field: "entityId" | "instigatorId";
    label: string;
    method: "getByEntityId" | "getByInstigatorId";
  };
  formValues: FormValues;
  isLoadingEvents: boolean;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingEvents: React.Dispatch<React.SetStateAction<boolean>>;
  formDescription?: string;
}> = ({
  debug,
  request,
  setSubmit,
  formValues,
  isLoadingEvents,
  setIsLoadingEvents,
  formDescription,
}) => {
  const navigate = useNavigate();

  const setFormInQueryParams = (values: Utils.FormValues): void => {
    const id = values[request.field] || undefined;
    const startTime = values.startTime || undefined;
    const endTime = values.endTime || undefined;

    const searchParams: Record<string, any> = {};

    if (startTime) searchParams.startTime = startTime;
    if (endTime) searchParams.endTime = endTime;
    searchParams.order = values.order;

    navigate({
      pathname: `${id}`,
      search: `?${createSearchParams(searchParams)}`,
    });

    setSubmit(true);
  };

  return (
    <Formik
      initialValues={{
        ...formValues,
      }}
      enableReinitialize
      validationSchema={Utils.EventSearchSchema}
      onSubmit={async (values, formikHelpers) => {
        setIsLoadingEvents(true);

        // Additional non Yuppable validations
        if (!values[request.field]) {
          formikHelpers.setFieldError(request.field, `You must provide an ${request.label}.`);
          return;
        }

        if (values.startTime && !values.endTime) {
          formikHelpers.setFieldError(
            "endTime",
            "You must provide an end time to query in timeframe.",
          );
          return;
        }

        if (values.endTime && !values.startTime) {
          formikHelpers.setFieldError(
            "startTime",
            "You must provide a start time to query in timeframe.",
          );
          return;
        }

        setFormInQueryParams(values);
      }}
    >
      {({ errors, touched, values, ...formikHelpers }) => (
        <Form>
        {formDescription && <p style={{ opacity: 0.75, marginBottom: 0, marginTop: "25px" }}>{formDescription}</p>}
          <Grid container spacing={2}>
            <Grid item xs={6} xl={4}>
              <p style={{ fontWeight: "bold", marginBottom: 0 }}>Required Fields</p>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <p>Id</p>
                  <TextField
                    id={request.field}
                    name={request.field}
                    label={request.label}
                    variant="standard"
                    fullWidth
                    value={values[request.field]}
                    onChange={(e) => {
                      formikHelpers.handleChange(e);
                    }}
                    onBlur={formikHelpers.handleBlur}
                    error={touched[request.field] && Boolean(errors[request.field])}
                    helperText={touched[request.field] && errors[request.field]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <p id="order-radio-buttons">Order</p>
                    <RadioGroup
                      row
                      aria-labelledby="order-radio-buttons"
                      name="order"
                      value={values.order}
                      onChange={(e) => {
                        formikHelpers.handleChange(e);
                      }}
                      onBlur={formikHelpers.handleBlur}
                      onError={() => touched.order && Boolean(errors.order)}
                    >
                      <FormControlLabel
                        sx={{ width: "45%" }}
                        value="asc"
                        control={<Radio />}
                        label="Asc"
                      />
                      <FormControlLabel
                        sx={{ width: "45%" }}
                        value="desc"
                        control={<Radio />}
                        label="Desc"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} xl={4}>
              <p style={{ fontWeight: "bold", marginBottom: 0 }}>Optional Fields</p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p id="order-radio-buttons">Timeframe</p>
                  <DateTimePicker
                    sx={{ margin: 0.5, width: "48%" }}
                    label="Start Time"
                    value={values.startTime}
                    onAccept={() => (touched.startTime = true)}
                    onChange={(value) => formikHelpers.setFieldValue("startTime", value, true)}
                    slotProps={{
                      textField: {
                        error: touched.startTime && Boolean(errors.startTime),
                        helperText: errors.startTime,
                      },
                    }}
                  />
                  <DateTimePicker
                    label="End Time"
                    sx={{ margin: 0.5, width: "48%" }}
                    value={values.endTime}
                    onAccept={() => (touched.endTime = true)}
                    onChange={(value) => {
                      formikHelpers.setFieldValue("endTime", value, true);
                    }}
                    slotProps={{
                      textField: {
                        error: touched.endTime && Boolean(errors.endTime),
                        helperText: errors.endTime,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            style={{ marginTop: 10, marginBottom: 10, width: "10rem", height: "3rem" }}
            type="submit"
            disabled={isLoadingEvents}
          >
            {isLoadingEvents ? "SUBMITTING" : "SUBMIT"}
          </Button>
          {debug && <pre>{JSON.stringify({ values, errors, touched }, null, 2)}</pre>}
        </Form>
      )}
    </Formik>
  );
};

export default EventSearchForm;
