import { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

import { Link } from "react-router-dom";

const Row: React.FC<{
  collapsibleJson?: {
    title: string;
    data: Record<string, any>;
  }[];
  collapsibleInternalLinks?: {
    relativePath: string;
    label: string;
  }[];
  collapsibleExternalLinks?: {
    link: string;
    label: string;
  }[];
  cellItem: any[];
  keyString: string;
}> = (props) => {
  const [open, setOpen] = useState(false);

  const isCollapsible =
    props.collapsibleInternalLinks || props.collapsibleExternalLinks || props.collapsibleJson;

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {isCollapsible && (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {props.cellItem.map((item: any, index) => (
          <TableCell key={`tc-${index}-${props.keyString}`}>{item}</TableCell>
        ))}
      </TableRow>
      {isCollapsible && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <>
                {props.collapsibleInternalLinks && (
                  <Box sx={{ margin: 1 }}>
                    <>
                      <Typography variant="h6" gutterBottom component="div">
                        Quick Links
                      </Typography>
                      <Paper style={{ padding: 10, backgroundColor: "#F5F5F5" }}>
                        <Grid container spacing={2}>
                          {props.collapsibleInternalLinks.map((internalLink, index) => (
                            <Grid item key={`ql-${index}-ql-${props.keyString}-${internalLink.label}`}>
                              <Button
                                to={internalLink.relativePath}
                                component={Link}
                                variant="outlined"
                                sx={{
                                  height: "40px",
                                }}
                              >
                                {internalLink.label}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    </>
                  </Box>
                )}
                <Box sx={{ margin: 1 }}>
                  {props?.collapsibleJson?.map((section, index) => {
                    return (
                      <Fragment key={`cj-${index}-${props.keyString}-${section.title}`}>
                        <>
                          <Typography variant="h6" gutterBottom component="div">
                            {section.title}
                          </Typography>
                          <Paper style={{ padding: 10, backgroundColor: "#F5F5F5" }}>
                            <pre>{JSON.stringify(section.data, null, 2)}</pre>
                          </Paper>
                        </>
                      </Fragment>
                    );
                  })}
                </Box>
                {props.collapsibleExternalLinks && (
                  <Box sx={{ margin: 1 }}>
                    <>
                      <Typography variant="h6" gutterBottom component="div">
                        External Links
                      </Typography>
                      <Paper style={{ padding: 10, backgroundColor: "#F5F5F5" }}>
                        <Grid container spacing={2}>
                          {props.collapsibleExternalLinks.map((externalLink, index) => (
                            <Grid
                              item
                              key={`el-${index}-el-${props.keyString}-${externalLink.label}`}
                            >
                              <Button
                                to={externalLink.link}
                                component={Link}
                                target="_blank"
                                variant="outlined"
                                sx={{
                                  height: "40px",
                                }}
                              >
                                {externalLink.label}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    </>
                  </Box>
                )}
              </>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default Row;
