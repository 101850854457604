import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Row from "./components/row";

/**
 * @description Renders a table based on an array of values passed. Can have expandible accordion on every record if desired.
 *
 * @property "items" Base data passed to the table.
 * @property "rowConfiguration" Array of Objects that determines the table headers with the "label" property, and how the values will be accessed from "items" based on the "accessor" property.
 * @property (optional) "collapsibleItems" Array of Objects that will cause the table to render an expandible item. Each object cas a "title" property that will be displayed as the title for each section, and an "accessor" property for the data that will be displayed under the title.
 */
const ExpandibleTable: React.FC<{
  items: Record<string, any>[];
  rowConfiguration: { label: string; accessor: string }[];
  collapsibleInternalLinks?: {
    relativePath: string;
    urlString: string;
    labelString: string;
    dynamicAttributes?: Record<string, string>;
  }[];
  collapsibleExternalLinks?: {
    labelString: string;
    urlString: string;
    dynamicAttributes?: Record<string, string>;
  }[];
  collapsibleJson: {
    title: string;
    accessor: string;
  }[];
}> = (props) => {
  const tableHeaders = props.rowConfiguration.map((item) => item.label);
  const accessors = props.rowConfiguration.map((item) => item.accessor);

  const getCellItemContent = ({
    keys,
    item,
  }: {
    keys: string[];
    item: Record<string, any>;
  }): any[] => keys.map((key) => key.split(".").reduce((acc, key) => acc[key], item));

  const isCollapsible =
    props.collapsibleJson || props.collapsibleInternalLinks || props.collapsibleExternalLinks;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {isCollapsible ? <TableCell /> : null}
            <TableCell>#</TableCell>
            {tableHeaders.map((header, index) => (
              <TableCell key={`tc-th-${header}-${index}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((item, index) => {
            const cellItems = getCellItemContent({ keys: accessors, item });

            const getItemPathvalue = (path: string) =>
              path.split(".").reduce((acc: Record<string, any>, key) => acc[key], item);

            const parsedCollapsibleInternalLinks =
              props.collapsibleInternalLinks &&
              props.collapsibleInternalLinks.map((linkInfo) => {
                let urlProps = linkInfo.urlString;
                let label = linkInfo.labelString;

                for (const attribute in linkInfo.dynamicAttributes) {
                  const attributePathValue = linkInfo.dynamicAttributes[attribute];
                  const attributeItemValue = getItemPathvalue(attributePathValue);
                  const attributeValue = attributeItemValue || attributePathValue;

                  urlProps = urlProps.replace(attribute, String(attributeValue));
                  label = label.replace(attribute, String(attributeValue));
                }

                return {
                  relativePath: `${linkInfo.relativePath}/${urlProps}`,
                  label,
                };
              });

            const parsedCollapsibleExternalLinks =
              props.collapsibleExternalLinks &&
              props.collapsibleExternalLinks.map((linkInfo) => {
                let url = linkInfo.urlString;
                let label = linkInfo.labelString
                for (const attribute in linkInfo.dynamicAttributes) {
                  const attributePathValue = linkInfo.dynamicAttributes[attribute];
                  const attributeItemValue = getItemPathvalue(attributePathValue);
                  const attributeValue = attributeItemValue || attributePathValue;

                  url = url.replace(attribute, String(attributeValue));
                  label = label.replace(attribute, String(attributeValue));
                }

                return {
                  label,
                  link: url,
                };
              });

            const parsedCollapsibleJson =
              props.collapsibleJson &&
              props.collapsibleJson.map((collapsibleJson) => ({
                title: collapsibleJson.title,
                data: getItemPathvalue(collapsibleJson.accessor),
              }));

            const key = `${index}-${cellItems.join("-")}-${Math.random()}`;

            return (
              <Row
                collapsibleJson={parsedCollapsibleJson}
                collapsibleInternalLinks={parsedCollapsibleInternalLinks}
                collapsibleExternalLinks={parsedCollapsibleExternalLinks}
                cellItem={[index + 1, ...cellItems]}
                key={key}
                keyString={key}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExpandibleTable;
