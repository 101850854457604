import React from "react";

import { CenterContent } from "components/center-content/center-content";
import LoadingSpinner from "components/loading-spinner";

type LoadingCenterNavigatorProps = {
  text: string;
}

export const LoadingCenterNavigator: React.FC<LoadingCenterNavigatorProps> = ({
  text
}) => {

  return (
    <CenterContent
      gap="50px"
      marginTop="10px"
    >
      <div>{text}</div>
      <LoadingSpinner style={{
        display: "flex",
        justifyContent: "center"
      }} />
    </CenterContent>
  );
};