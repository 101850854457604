import * as Yup from "yup";

export const EventSearchSchema = Yup.object<FormValues>().shape({
  entityId: Yup.string(),
  instigatorId: Yup.string(),
  nextPage: Yup.string().nullable(),
  startingCount: Yup.number().required(),
  startTime: Yup.date().nullable(),
  endTime: Yup.date().nullable(),
  order: Yup.string().required().oneOf(["desc", "asc"]),
});

export type FormValues = {
  entityId: string;
  instigatorId: string;
  nextPage: string | null;
  startingCount: number;
  startTime: null;
  endTime: null;
  order: "desc" | "asc";
};

export const InitialFormValues: FormValues = {
  entityId: "",
  instigatorId: "",
  nextPage: null,
  startingCount: 0,
  startTime: null,
  endTime: null,
  order: "desc" as "desc" | "asc",
};
