import React, { useContext } from "react";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import GetAroundLogo from "logo/getaround.svg"

import { UIInteractionContext, SelectedMainNavigationEnum } from "globals/context/ui-interaction-context";

export const MainNavigationDrawer: React.FC = () => {
  const {
    isNavigationDrawerOpen,
    setIsNavigationDrawerOpen,
    selectedMainNavigation,
    setSelectedMainNavigation
  } = useContext(UIInteractionContext);

  const handleCloseDrawer = (openNavigation: SelectedMainNavigationEnum | null) => {
    if (openNavigation) {
      setSelectedMainNavigation(openNavigation)
    }
    setIsNavigationDrawerOpen(false);
  }

  return (
    <Drawer
      anchor="left"
      open={isNavigationDrawerOpen}
      onClose={() => handleCloseDrawer(null)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
      >
        <List>
          <ListItem key="Get Around Logo" >
            <ListItemIcon style={{ display: "flex", flex: 1 }}>
              <img
                src={GetAroundLogo}
                alt=""
                style={{
                  display: "block",
                  height: "30px",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              />
            </ListItemIcon>
          </ListItem>

          <Link
            to="/event-search"
            component={NavLink}
            onClick={() => handleCloseDrawer(SelectedMainNavigationEnum.EventSearch)}
          >
            <ListItemButton selected={selectedMainNavigation === SelectedMainNavigationEnum.EventSearch}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Event Search"} />
            </ListItemButton>
          </Link>

          {/* <Link
            to="/claims"
            component={NavLink}
            onClick={() => handleCloseDrawer(SelectedMainNavigationEnum.Claims)}
          >
            <ListItemButton selected={selectedMainNavigation === SelectedMainNavigationEnum.Claims}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Claims"} />
            </ListItemButton>
          </Link>

          <Link
            to="/rentals"
            component={NavLink}
            onClick={() => handleCloseDrawer(SelectedMainNavigationEnum.Rentals)}
          >
            <ListItemButton selected={selectedMainNavigation === SelectedMainNavigationEnum.Rentals}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Rentals"} />
            </ListItemButton>
          </Link> */}
        </List>
      </Box>
    </Drawer>
  )
}