import React, { useState } from "react";
import { Outlet } from "react-router";

import { Seo } from "components/seo/seo";
import EventSearchForm from "pages/event-search/components/event-search-form/event-search-form";
import { FormValues, InitialFormValues } from "pages/event-search/components/event-search-form/event-search-form-utils";

export const UserActivity: React.FC = () => {
  const [submit, setSubmit] = useState<boolean>(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(InitialFormValues);

  return (
    <Seo
      title="Event Explorer - Instigator Search"
      description="Search for everything that a user has done in our system by user id."
    >
      <EventSearchForm
        formValues={formValues}
        request={{
          field: "instigatorId",
          label: "User Id",
          method: "getByInstigatorId",
        }}
        setSubmit={setSubmit}
        isLoadingEvents={isLoadingEvents}
        setIsLoadingEvents={setIsLoadingEvents}
        formDescription="Search for the activity of any user in our system through the use of their user id."
      />
      <Outlet
        context={{
          request: {
            field: "instigatorId",
            label: "Instigator Id",
            method: "getByInstigatorId",
          },
          submit,
          setSubmit,
          setFormValues,
          formValues,
          setIsLoadingEvents,
          isLoadingEvents,
        }}
      />
    </Seo>
  );
};
