import React from "react";
import GoogleButton from "react-google-button";
import { useAuth } from "react-oidc-context";

import { CenterContent } from "components/center-content/center-content";
import GetAroundLogo from "../../logo/getaround.svg";

export const Login: React.FC = () => {
  const auth = useAuth();

  return (
    <CenterContent
      gap="170px"
      marginTop="20vh"
    >
      <div>
        <img
          src={GetAroundLogo}
          alt=""
          style={{
            height: "150px"
          }}
        />
        <div
          style={{
            textAlign: "center",
            fontWeight: "lighter",
            fontSize: "55px"
          }}
        >
          EvE: Event Explorer
        </div>
      </div>
      <div
        style={{
          height: "50px"
        }}
      >
        <GoogleButton onClick={() => auth.signinPopup()} />
      </div>
    </CenterContent>
  );
};