import React, { createContext, PropsWithChildren, useState } from "react";

export enum SelectedMainNavigationEnum {
  EventSearch = "event-search",
  Claims = "claims",
  Rentals = "rentals"
}

type UIInteractionContextProps = {
  isNavigationDrawerOpen: boolean;
  setIsNavigationDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trigger: any;
  setTrigger: React.Dispatch<React.SetStateAction<any>>;
  selectedMainNavigation: SelectedMainNavigationEnum;
  setSelectedMainNavigation: React.Dispatch<React.SetStateAction<SelectedMainNavigationEnum>>;
};

export const UIInteractionContext = createContext<UIInteractionContextProps>({
  isNavigationDrawerOpen: false,
  setIsNavigationDrawerOpen: () => {},
  trigger: undefined,
  setTrigger: () => {},
  selectedMainNavigation: SelectedMainNavigationEnum.EventSearch,
  setSelectedMainNavigation: () => {},
});

export const UIInteractionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);
  const [trigger, setTrigger] = useState<any>(undefined);
  const [selectedMainNavigation, setSelectedMainNavigation] = useState<SelectedMainNavigationEnum>(SelectedMainNavigationEnum.EventSearch);

  return (
    <UIInteractionContext.Provider
      value={{
        isNavigationDrawerOpen,
        setIsNavigationDrawerOpen,
        trigger,
        setTrigger,
        selectedMainNavigation,
        setSelectedMainNavigation,
      }}
    >
      {children}
    </UIInteractionContext.Provider>
  )
}

