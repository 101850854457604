const origin = window.location.origin;
const REACT_APP_IDP_CLIENT_ID = process.env.REACT_APP_IDP_CLIENT_ID; 
const REACT_APP_IDP_AUTHORITY = process.env.REACT_APP_IDP_AUTHORITY; 

export const oidcConfig = {
  authority: `${REACT_APP_IDP_AUTHORITY}/`,
  client_id: `${REACT_APP_IDP_CLIENT_ID}`,
  redirect_uri: `${origin}/`,
  response_type: "code",
  auth_code_grant_type: "authorization_code",
  refresh_token_grant_type: "refresh_token",
  scope: "openid profile email hc-rules",
  acr_values: "passport_social",
  extraQueryParams: {
    preselectedExternalProvider: "ewogICAicHJvdmlkZXIiIDogImdvb2dsZSIKfQ==",
    redirectUri: `${REACT_APP_IDP_AUTHORITY}/identity/authentication/getauthcode`
  },
  metadata: {
    authorization_endpoint: `${REACT_APP_IDP_AUTHORITY}/oxauth/authorize.htm`,
    token_endpoint: `${REACT_APP_IDP_AUTHORITY}/oxauth/restv1/token`,
    jwks_uri: `${REACT_APP_IDP_AUTHORITY}/oxauth/restv1/jwks`,
    revocation_endpoint: `${REACT_APP_IDP_AUTHORITY}/oxauth/restv1/revoke`,
    end_session_endpoint: `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${origin}/signout`
  },
  onSigninCallback: () => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname,
    );
  },
  accessTokenExpiringNotificationTimeInSeconds: 60,
  automaticSilentRenew: true,
};