import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";

import { Seo } from "components/seo/seo";

/**
 * @description
 */
export const EventSearch: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isBaseEventSearchRoute = matchPath("/event-search", pathname);

  useEffect(() => {
    if (isBaseEventSearchRoute) {
      navigate("/event-search/entity-history");
    }
  }, [isBaseEventSearchRoute, navigate]);

  const patterns = [
    "event-search/entity-history/:id",
    "event-search/entity-history",
    "event-search/user-activity/:id",
    "event-search/user-activity",
  ];

  const routeMatch = useRouteMatch(patterns);

  const currentPath = routeMatch?.pattern?.path || "";

  const currentTab = currentPath?.includes("/:id")
    ? currentPath.slice(0, currentPath.length - 4)
    : currentPath;

  return (
    <Seo title="Event Explorer - Event Search">
      <Tabs value={currentTab ? currentTab : "event-search/entity"}>
        <Tab label="Entity History" value="event-search/entity-history" to="entity-history" component={Link} />
        <Tab label="User Activity" value="event-search/user-activity" to="user-activity" component={Link} />
      </Tabs>

      <Outlet />
    </Seo>
  );
};

const useRouteMatch = (patterns: any) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    const possibleMatch = matchPath(pattern, pathname);

    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};
