import { PropsWithChildren } from "react";
import { useAuth } from "react-oidc-context";

export const PrivateRoute: React.FC<PropsWithChildren> = ({children}) => {
  const auth = useAuth();

  const isValidUser = auth.isAuthenticated &&
    auth.user?.profile?.roles === "admin" && 
    auth.user?.id_token &&
    auth.user?.profile?.email?.includes("@getaround.com")

  return isValidUser ? (
    <>
      {children}
    </>
  ) : null
}