export const parseEventInfo = (events: any) => { 
  return events.map((event: any) => ({
    eventName: event?.eventName,
    id: event?.data?.data?.id,
    instigator: event?.data?.instigator,
    date: new Date(event?.timestamp).toLocaleString(),
    timestamp: event?.timestamp,
    eventData: event?.data?.data,
    trace: event?.data?.trace,
    metadata: {
      domain: event?.domain,
      eventName: event?.eventName,
      schemaVersion: event?.data?.schemaVersion,
      eventDateTime: new Date(event?.timestamp).toLocaleString(),
      storedAt: new Date(event?.createdAt).toLocaleString(),
      sortKey: event?.timestampEventNameInstigatorIdDomain,
    },
  }));
};
